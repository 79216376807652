import React, { useState, useEffect } from 'react'

interface HeaderProps {
  jumpIntro: () => void
  jumpAbout: () => void
  jumpWork: () => void
  jumpContact: () => void
}

const Header: React.FC<HeaderProps> = ({ jumpIntro, jumpAbout, jumpWork, jumpContact }) => {
  const [bgPinned, setBgPinned] = useState<boolean>(false)

  useEffect(() => {
    const handleScroll = (ev) => {
      if (window.pageYOffset > 100 && bgPinned === false ) {
        setBgPinned(true)
      }
      if (window.pageYOffset < 100 && bgPinned === true ) {
        setBgPinned(false)
      }
    }

    document.addEventListener('scroll', handleScroll)

    return () => {
      document.addEventListener('scroll', handleScroll)
    }
  }, [bgPinned])

  return (
    <div className="fixed z-40 w-full px-8 lg:px-20 py-3 header">
      <div className={`header-bg h-16 sm:h-12 ${ bgPinned ? 'header-bg-pin' : '' }`}></div>
      <header id="AppHeader" className={`flex flex-wrap ${ bgPinned ? 'header-fg-pin' : '' }`}>
        <div className="w-full sm:w-3/5 sm:flex-1 pr-8">
          <div className={`${ bgPinned ? 'block' : 'hidden' }`}>
            <div className="main-title cursor-pointer" onClick={jumpIntro}>cffd.ink</div>
            <div className="main-subtitle">creative / functional / fullstack / development</div>
          </div>
        </div>
        <div className="">
          <div className="flex-1 flex font-light text-sm mt-1">
            <div className="cursor-pointer hover:underline" onClick={jumpAbout}>About</div>
            <div className="mx-2">/</div>
            <div className="cursor-pointer hover:underline" onClick={jumpWork}>Work</div>
            <div className="mx-2">/</div>
            <div className="cursor-pointer hover:underline" onClick={jumpContact}>Contact</div>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Header
