import React, { FC, useRef } from 'react'
import Header from './header'

interface DefaultLayoutProps {
  comp: any
}

const DefaultLayout: FC<DefaultLayoutProps> = ({ comp }) => {
  const introRef = useRef<HTMLDivElement>(null)
  const aboutRef = useRef<HTMLDivElement>(null)
  const workRef = useRef<HTMLDivElement>(null)
  const contactRef = useRef<HTMLDivElement>(null)
  const Component = comp

  const jumpIntro = () => {
    introRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const jumpAbout = () => {
    aboutRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const jumpWork = () => {
    workRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
  
  const jumpContact = () => {
    contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <div>
      <Header 
        jumpIntro={jumpIntro} 
        jumpAbout={jumpAbout} 
        jumpWork={jumpWork} 
        jumpContact={jumpContact}
      />
      <Component 
        jumpAbout={jumpAbout} 
        jumpWork={jumpWork} 
        jumpContact={jumpContact} 
        introRef={introRef} 
        aboutRef={aboutRef} 
        workRef={workRef} 
        contactRef={contactRef}
      />
    </div>
  )
}

export default DefaultLayout
