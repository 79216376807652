import React from 'react'
import Particles from 'react-particles-js'
import { scrollDown } from '../utils'
import { Spring } from "react-spring/renderprops"
import { useInView } from 'react-intersection-observer'
import ReactLogo from '../images/react.svg'
import ElixirLogo from '../images/elixir-logo.svg'

interface IntroProps {
  introRef: string
  jumpAbout: () => void
}

const Intro: React.FC<IntroProps> = ({ introRef, jumpAbout }) => {
  const { ref, inView } = useInView()
  return (
    <div className="main relative" ref={introRef}>
      <div className="absolute w-full h-full z-0">
        <div className="px-16 container mx-auto">
          <div className="title">
            <h1 className="text-5xl md:text-6xl t-shadow">
              cffd
              <img src={ReactLogo} className="h1-react inline-block mt-6" alt="React logo dot" />
              <span className="relative inline-block">
                <img src={ElixirLogo} className="h1-elixir" alt="Elixir logo dot" />
                i
              </span>
              nk
            </h1>
            <h4 className="text-lg md:text-2xl t-shadow">creative / functional / fullstack / development</h4>
            <Spring
              config={{ tension: 280, friction: 120 }}
              delay={400}
              to={{
                opacity: inView ? 1 : 0,
                transform: inView
                  ? "translateY(0)"
                  : "translateY(140px)",
              }}
            >
              {(props) => (
                <h3 ref={ref} className="text-xl md:text-3xl t-shadow leading-none" style={{ ...props }}>
                  Delivering innovative, highly functional,
                  <br />
                  visually appealing and feature-rich web applications
                </h3>
              )}
            </Spring>
          </div>
        </div>
      </div>
      <div className="absolute w-full h-full z-10">
        <Particles params={{
          particles: {
            number: {
              value: 80,
              density: {
                enable: true,
                value_area: 1200
              }
            },
            color: {
              value: '#666666'
            },
            shape: {
              type: 'circle',
              stroke: {
                width: 0,
                color: '#000000'
              },
              polygon: {
                nb_sides: 5
              },
              image: {
                src: 'img/github.svg',
                width: 100,
                height: 100
              }
            },
            opacity: {
              value: 0.5,
              random: false,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false
              }
            },
            size: {
              value: 3,
              random: true,
              anim: {
                enable: false,
                speed: 40,
                size_min: 0.1,
                sync: false
              }
            },
            line_linked: {
              enable: true,
              distance: 140,
              color: '#666666',
              opacity: 0.6,
              width: 1
            },
            move: {
              enable: true,
              speed: 2,
              direction: 'none',
              random: false,
              straight: false,
              out_mode: 'out',
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
              }
            }
          },
          interactivity: {
            detect_on: 'canvas',
            events: {
              onhover: {
                enable: true,
                mode: 'repulse'
              },
              onclick: {
                enable: true,
                mode: 'push'
              },
              resize: true
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 1
                }
              },
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8
              },
              repulse: {
                distance: 200,
                duration: 0.4
              },
              push: {
                particles_nb: 4
              },
              remove: {
                particles_nb: 2
              }
            }
          },
          retina_detect: true
        }}
        />
        {scrollDown(jumpAbout)}
      </div>
    </div>
  )
}

export default Intro
