import React, { FC } from 'react'
import { Spring } from "react-spring/renderprops"
import { InView } from 'react-intersection-observer'

const works = [
  {
    title: 'Excyte',
    logo: 'excyte.svg',
    name: 'excyte',
    filename: 'excyte.png',
    objective: `<p>To provide highly available online tools to help teams and 
      organizations manage marketing and sales. This is done through automation 
      and machine learning to maximize efficiency and ROI. A few of the main 
      requirements of this project include the ability to create and manage 
      collaborative marketing materials, easily place digital advertising to 
      generate engaged leads, and communicate through group chat/SMS. All with
      the goal that these tools working in concert will lend itself to a seamless 
      sales experience.
      </p>`,
    solution: `<p>Since acquisition and outreach to new leads was the ultimate goal 
      of this project I chose to use an emerging technology rooted in telecom called 
      Elixir. With Elixir I was able to leverage Twilio and Social network advertising 
      API's to do the heavy lifting, while utilizing React and Apollo on the client 
      to create a rich user experience. I also incorporated Absinthe/GraphQL subscriptions 
      and websockets to keep the team and communications in sync. This project is currently
      in its final testing phases and should be released shortly.</p>`,
    link: 'https://excyte.io',
    stack: [
      { logo: 'elixir-logo.svg', name: 'Elixir', link: 'https://nodejs.org/en/' },
      { logo: 'phoenix-o.svg', name: 'Phoenix', link: 'https://www.phoenixframework.org/' },
      { logo: 'graphql.svg', name: 'GraphQL', link: 'https://graphql.org/' },
      { logo: 'react.svg', name: 'React', link: 'https://reactjs.org/' },
      { logo: 'typescript.svg', name: 'Typescript', link: 'https://www.typescriptlang.org/' },
      { logo: 'postgresql.svg', name: 'Pg SQL', link: 'https://www.postgresql.org/' },
      { logo: 'apollo.svg', name: 'Apollo', link: 'https://www.apollographql.com/' },
      { logo: 'google-cloud.svg', name: 'GCP', link: 'https://cloud.google.com/' },
      { logo: 'tailwind.svg', name: 'Tailwind', link: 'https://tailwindcss.com/' }
    ]
  },
  {
    title: 'Homefilming',
    name: 'homefilming',
    logo: 'hf_logo_white.svg',
    filename: 'agent_gallery.png',
    objective: `<p>To create online service to facilitate real estate agents being 
      able to order aerial photographs and videos of properties for marketing of 
      residential homes. Requirements included billing the agent who orders the 
      filming, followed by alerting local pilots of the filming opportunity. Once 
      the filming was completed/uploaded it gets reviewed and the acceptance of 
      the video triggers an automatic payout to the pilot.</p>`,
    solution: `<p>Leveraging Postgres's Earthdistance Extention for looking up 
      local pilots based off Lat/Lng as well as several API's including Google 
      Maps, Amazon S3, Transloadit for efficient media processing, and Stripe 
      for payments and payouts. Also, there is an admin dashboard to keep 
      track of pilots that don't follow through on missions and to track all 
      orders and their statuses.</p>`,
    link: 'https://homefilming.herokuapp.com',
    stack: [
      { logo: 'node.svg', name: 'Node', link: 'https://nodejs.org/en/' },
      { logo: 'graphql.svg', name: 'GraphQL', link: 'https://graphql.org/' },
      { logo: 'react.svg', name: 'React', link: 'https://reactjs.org/' },
      { logo: 'postgresql.svg', name: 'Pg SQL', link: 'https://www.postgresql.org/' },
      { logo: 'apollo.svg', name: 'Apollo', link: 'https://www.apollographql.com/' },
      { logo: 'webpack.svg', name: 'Webpack', link: 'https://webpack.js.org/' },
      { logo: 'jest.svg', name: 'Jest', link: 'https://jestjs.io/' },
      { logo: 'aws-s3.svg', name: 'AWS S3', link: 'https://aws.amazon.com/s3/' },
      { logo: 'tailwind.svg', name: 'Tailwind', link: 'https://tailwindcss.com/' },
      { logo: 'flow.svg', name: 'Flow', link: 'https://flow.org/en/' }
    ]
  },
  {
    title: 'Cloud MLX',
    name: 'mlx',
    logo: 'cloud-mlx.svg',
    filename: 'mlx2x.jpg',
    objective: `<p>To Create an MLS search/social network with features that include
      natural language search (ie. "4 Beds 3 baths $2mil") and drawing polygon areas 
      on a map to search locations. Other requirements included...must be able to search 
      listings by agent, be integrated with existing WR products, a UI experience as 
      familiar as Trulia/Zillow, and be much more performant than current MLS systems 
      on the market. </p>`,
    solution: `<p>In order to achieve all these ambitious goals, we decided on building 
      a single page application with a custom javascript front-end and a service-oriented 
      Rails API. All MLS data was normalized and indexed through Elasticsearch for speedy 
      lookups and most proofs of concepts were created in isolation using Node/Express 
      with MongoDB.</p><br /><p class="t-shadow italic">Cloud MLX was awarded the 
      2016 Inman Innovator Award for Most Innovative Technology.</p>`,
    link: 'https://cloudmlx.com/',
    stack: [
      { logo: 'ruby.svg', name: 'Ruby', link: 'https://www.ruby-lang.org/en/' },
      { logo: 'elasticsearch.svg', name: 'Elastic search', link: 'https://www.elastic.co/' },
      { logo: 'coffeescript.svg', name: 'Coffee script', link: 'https://coffeescript.org/' },
      { logo: 'postgresql.svg', name: 'Pg SQL', link: 'https://www.postgresql.org/' },
      { logo: 'pug.svg', name: 'Pug', link: 'https://pugjs.org/api/getting-started.html' },
      { logo: 'bootstrap.svg', name: 'Bootstrap', link: 'https://getbootstrap.com/' },
      { logo: 'node.svg', name: 'Node', link: 'https://nodejs.org/en/' },
      { logo: 'mongodb.svg', name: 'MongoDB', link: 'https://aws.amazon.com/s3/' }
    ]
  }
]

interface WorkProps {
  workRef: string
  jumpContact: () => void
}

const Work: FC<WorkProps> = ({ workRef }) => {
  return (
    <div className="work relative" ref={workRef}>
      <div className=" py-4 md:py-8 px-6 container mx-auto">
        <h2 className="text-4xl md:text-5xl title text-center mt-8 md:mt-16 t-shadow">work</h2>
        <h4 className="text-center mt-2 text-lg md:text-2xl">Check my latest work in design, and app development with Javascript, Elixir and Ruby.</h4>
        <div className="flex flex-wrap mt-8">
          <div className="w-full px-4">
            <div className="pt-4 fancy-title text-xl md:text-3xl t-shadow">
              recent projects
            </div>
            <div className="flex flex-wrap -mx-4 mt-2">
              { works.map((wk, i) => (
                <InView key={`work_${i}`}>
                  {({ inView, ref }) => (
                    <Spring
                      delay={400}
                      to={{
                        opacity: inView ? 1 : 0,
                        transform: inView
                          ? "translateX(0)"
                          : "translateX(-200px)",
                      }}
                    >
                      {(props) => (
                        <div ref={ref} className="work-item py-6" style={{ ...props }}>
                          <div className="mx-6">
                            <div className="w-full flex flex-wrap">
                              <div className="flex-1 pr-40 mb-4 relative">
                                { wk.name === 'mlx' ?(
                                  <div className="opacity-50" style={{ position: 'absolute', top: -30, right: '34%', transform: 'rotate(-30deg)' }}>
                                    <img className="block w-32 mx-auto h-32 text-white" src={`${require('../images/inman_seal.svg')}`} alt="Inman award" />
                                  </div>
                                ) : null }
                                <a href={wk.link} target="_blank" rel="noopener noreferrer">
                                  <img className="block h-16" src={`${require(`../images/${wk.logo}`)}`} alt={wk.name} />
                                </a>
                              </div>
                              <div className="flex flex-wrap -px-2 mb-2">
                                { wk.stack ? wk.stack.map(st => (
                                  <div key={`st_item_${st.name}`} className="w-16 px-2 mb-2">
                                    <img className="block w-3/5 md:w-1/2 mx-auto h-8" src={`${require(`../images/${st.logo}`)}`} alt={st.name} />
                                    <p className="text-xs text-center t-shadow">{st.name}</p>
                                  </div>
                                )) : null }
                              </div>
                            </div>
                          </div>
                          <div className="px-6">
                            <div className="flex flex-wrap -mx-6">
                              <div className="w-full md:w-1/3 px-6">
                                <img className="block mt-4" src={`${require(`../images/${wk.filename}`)}`} alt={wk.name} />
                                <p className="text-center mt-4 text-sm text-blue-200">
                                  url ::
                                  {' '}
                                  <a href={wk.link} target="_blank" rel="noopener noreferrer">{ wk.link || '' }</a>
                                </p>
                              </div>
                              <div className="w-full md:w-1/3 px-6">
                                <h4 className="text-lg t-shadow">Objective</h4>
                                <div className="text-sm" dangerouslySetInnerHTML={{ __html: wk.objective }} />
                              </div>
                              <div className="w-full md:w-1/3 px-6">
                                <h4 className="text-lg t-shadow">Solution</h4>
                                <div className="text-sm" dangerouslySetInnerHTML={{ __html: wk.solution }} />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Spring>
                  )}
                </InView>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Work
