import React, { FC } from 'react'
import { scrollDown } from '../utils'
import { Spring } from "react-spring/renderprops"
import { useInView } from 'react-intersection-observer'
import me from '../images/headshot.png'
import pdfIcon from '../images/pdf.svg'


interface AboutProps {
  aboutRef: string
  jumpWork: () => void
}

const About: FC<AboutProps> = ({ aboutRef, jumpWork }) => {
  const profile = 'Since the creation of my first app in 2003, I\'ve always been drawn to the overlap between design and development. My skills are broad: from UX to design, front-end to back-end development. I enjoy each aspect, and love building sites from start to finish & exploring new technologies.'
  const { ref, inView } = useInView()
  return (
    <div className="about relative" ref={aboutRef}>
      <div className="py-8 md:py-12 px-6 container mx-auto">
        <div className="flex mt-8">
          <h2 className="text-4xl md:text-5xl title text-center t-shadow">about</h2>
          <h4 className="text-center text-md md:text-2xl mt-2 ml-4 t-shadow"> :: derek rush</h4>
        </div>
        <div className="header flex flex-wrap">
          <div className="w-full md:w-3/5 flex">
            <div className="pr-8 pt:1 pb-3 text-center md:text-left">
              <div className="pt-2 pb-4 text-sm md:text-lg leading-normal t-shadow">
                <div
                  style={{
                    background: `url(${me}) no-repeat`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                  }}
                  className="curve rounded-full border-grey-darker border-4 bolder-solid mr-12 float-left w-32 h-32"
                />
                {profile}
              </div>
            </div>
          </div>
          <div className="flex-1">
            <a href="/images/resume.pdf" target="_blank" className="no-underline hover:cursor-pointer">
              <div className="flex">
                <div className="md:pl-8">
                  <img className="block h-24" src={pdfIcon} alt="Resume icon" />
                </div>
                <div className="flex-1 pt-4">
                  <h3>My resume</h3>
                  <p className="text-sm md:text-lg t-shadow">uploaded :: 9-26-2020</p>
                </div>
              </div>
            </a>
            <a href="https://www.codewars.com/users/NuTheory" target="_blank" rel="noopener noreferrer">
              <img className="block w-full h-10" src="https://www.codewars.com/users/NuTheory/badges/large" alt="Codewars ranking" />
            </a>
          </div>
        </div>
        <Spring delay={400} to={{ opacity: inView ? 1 : 0 }}>
          {({ opacity }) => (
            <div ref={ref} className="body leading-tight md:leading-normal mt-0 md:mt-16 -mx-6 mb-8 flex flex-wrap" style={{ opacity }}>
              <div className="w-full md:w-1/3 p-6">
                <h3 className="text-xl md:text-3xl t-shadow">creative</h3>
                <p className="t-shadow">At cffd.ink we always strive to find the apex where
                  identity, visual, interaction, and UX design converge to achieve a fluid
                  flow of consistent messaging. The aesthetics of any brand or product should
                  always be intentional without appearing coerced into its application. Our
                  philosophy on creative work is to say a lot, with as little as possible to
                  keep your message clear and precise. Properly done the aspects of
                  creativity will we keep the end user engaged and instill a sense of
                  confidence in your target audience.
                </p>
              </div>
              <div className="w-full md:w-1/3 p-6">
                <h3 className="text-xl md:text-3xl t-shadow">functional</h3>
                <p className="t-shadow">When people talk about functional programming and/or
                  development they are referring to a simple task-based way of solving complex problems.
                  Functional problem-solving favors composition over inheritance (removing shared state) and
                  reducing side effects in an effort to increase the predictability of the behavior of your app.
                  Separating side effects from the rest of your logic can make a program easier to maintain,
                  test, debug, extend and refactor.
                </p>
              </div>
              <div className="w-full md:w-1/3 p-6">
                <h3 className="text-xl md:text-3xl t-shadow">fullstack</h3>
                <p className="t-shadow">Data stores to UI, running the entire stack and
                  knowing every technology being used gives a unique advantage to people with an immense
                  desire to build. It creates a sense of ownership and personal pride that permeates through
                  every tech decision from the language you choose to develop a project with to every
                  architectural building block you implement. Full-stack development is reserved for a
                  certain type of personality that has a deep sense of self-reliance and healthy &quot;get up
                  and GO!&quot; attitude.
                </p>
              </div>
            </div>
          )}
        </Spring>
        {scrollDown(jumpWork)}
      </div>
    </div>
  )
}

export default About
