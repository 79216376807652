import React, { FC } from 'react'

interface LinkButtonProps {
  href:string
  label:string
  classes: string
}


const LinkButton:FC<LinkButtonProps> = ({ href, label, classes }) => {

  const mouseMove = (e: any) => {
    const aElem = e
    const boundingClientRect = aElem.currentTarget.getBoundingClientRect()
    const docStyle = aElem.currentTarget.parentNode.style
    const x = aElem.clientX - boundingClientRect.left
    const y = aElem.clientY - boundingClientRect.top
    const xc = boundingClientRect.width/2
    const yc = boundingClientRect.height/2
    const dx = x - xc
    const dy = y - yc
    docStyle.setProperty('--rx', `${ dy/-1 }deg`)
    docStyle.setProperty('--ry', `${ dx/10 }deg`)
  }

  const mouseLeave = (e: any) =>{
    const aElem = e
    const docStyle = aElem.currentTarget.parentNode.style
    docStyle.setProperty('--ty', '0')
    docStyle.setProperty('--rx', '0')
    docStyle.setProperty('--ry', '0')
  }

  const mouseDown = (e: any) => {
    const aElem = e
    const docStyle = aElem.currentTarget.parentNode.style
    docStyle.setProperty('--tz', '-25px')
  }

  const mouseUp = (e: any) => {
    const aElem = e
    const docStyle = aElem.currentTarget.parentNode.style
    docStyle.setProperty('--tz', '-12px')
  }

  return (
    <div className="w-full button-boundary flex-wrap p-6">
      <a href={href}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={label}
        className={classes} 
        onMouseMove={mouseMove} 
        onMouseLeave={mouseLeave}
        onMouseDown={mouseDown}
        onMouseUp={mouseUp}
        data-title={label}
      >
        <span className="screen-reader-text">Contact me on {label}</span>
      </a>
    </div>
  )
}

export default LinkButton
