import React, { FC } from 'react'
import Intro from './intro'
import About from './about'
import Work from './work'
// import Playground from './playground'
import Contact from './contact'

interface IndexProps {
  introRef: string
  aboutRef: string
  workRef: string
  contactRef: string
  jumpAbout: () => void
  jumpWork: () => void
  jumpContact: () => void
}

const Index: FC<IndexProps> = ({ introRef, aboutRef, workRef, contactRef, jumpAbout, jumpWork, jumpContact }) => {
  return (
   <div>
    <Intro introRef={introRef} jumpAbout={jumpAbout} />
    <About aboutRef={aboutRef} jumpWork={jumpWork} />
    <Work workRef={workRef} jumpContact={jumpContact} />
    <Contact contactRef={contactRef} />
    <p className="text-sm px-6 py-1 dark-grey text-grey-darker t-shadow">All Rights Reserved © 2020 cffd.ink</p>
   </div> 
  )
}

export default Index