import React from 'react'
import DefaultLayout from './layouts/default'
import Main from './main/index'
import './styles/app.css'

function App() {
  return (
    <div className="App">
      <DefaultLayout comp={Main} />
    </div>
  )
}

export default App
